import validate from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  organization: () => import("/opt/buildhome/repo/middleware/organization.ts"),
  publication: () => import("/opt/buildhome/repo/middleware/publication.ts"),
  "publication-draft": () => import("/opt/buildhome/repo/middleware/publicationDraft.ts")
}